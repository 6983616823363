import React from 'react'
import styled from 'styled-components'

import NotFound from '../../assets/images/not-found.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledNotFound = styled(NotFound)`
  width: 300px;
`

const Desc = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
`

export default () => (
  <Wrapper>
    <StyledNotFound></StyledNotFound>
    <Desc>อุต๊ะ! ดูเหมือนเพจที่หาจะหลบซ่อนในกลีบเมฆ</Desc>
  </Wrapper>
)
